import { Fragment, useState } from 'react';
import ReactModal from 'react-modal';
import './Home.css';

const EmbedVideo = function(props) {
  return (
      <div class="bg-vid-container" dangerouslySetInnerHTML={{ __html: `
       <video
         muted
         autoplay
         playsinline
         src="${props.src}"
         id="myVideo"
       />,
     ` }}></div>
  )
}

function Home() {
  const [showModal, setShowModal] = useState(false);

  return (
    <Fragment>
      <EmbedVideo src="dig3.mp4" />
      <ReactModal isOpen={showModal}>
        <h1>Presenting for 1K live audience</h1>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/O9J9jW_QQPo?si=ThdQFkG6nzMle_oB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <div className='close' onClick={() => {setShowModal(false)}}>[x]</div>
      </ReactModal>
      <div className='menu'>
        <span className='link' onClick={() => {setShowModal(true)}}>AI TEAM</span>
        <span>|</span>
        <span><a href="https://www.linkedin.com/in/emorling/" target="_blank" rel="noreferrer" className='link'>CONTACT</a></span>
      </div>
    </Fragment>
  );
}

export default Home;
