// import './Home.css';
/*
import axios from "axios";
import { useEffect, useState } from "react";*/

function Transfers() {
  /*
  const [transfers, setTransfers] = useState([]);
  const [mints, setMints] = useState([]);
  useEffect(() => {
    axios.get('https://dig3-server.eu-4.evennode.com/transfers')
      .then((r) => setTransfers(r.data))
      .catch((e) => console.log(e));
    axios.get('https://dig3-server.eu-4.evennode.com/mints')
    .then((r) => setMints(r.data))
    .catch((e) => console.log(e));
  }, []);
  return (
    <div>
      <h1>Transfers</h1>
      {transfers && (<table><tbody>{transfers.map((l) => <tr key={l.address}><td><img width="50" src={l.image_url} alt={l.name}/></td><td>{l.name}</td><td>{l.transferCount}</td></tr>)}</tbody></table>)}
      <h1>Mints</h1>
      {mints && (<table><tbody>{mints.map((l) => <tr key={l.address}><td><img width="50" src={l.image_url} alt={l.name}/></td><td>{l.name}</td><td>{l.mintCount}</td></tr>)}</tbody></table>)}
    </div>
  );*/
  return (<div>nada</div>);
}

export default Transfers;
