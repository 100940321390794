import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";

import Home from './Home';
import Transfers from './Transfers';
import './App.css';

export default function App() {
  /* Routes nest inside one another. Nested route paths build upon
  parent route paths, and nested route elements render inside
  parent route elements. See the note about <Outlet> below. */
  return (<Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="transfers" element={<Transfers />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>);
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
